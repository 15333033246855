<template>
  <div class="create-account-standard">
    <div class="create-account-standard-form">
      <CreateAccountMagayaForm
        v-model="accountMagayaForm"
        @updateCheckedTerms="updateCheckedTerms"
        @updateEmailUpdates="updateEmailUpdates"
        ref="create-account-inputs-data"
      />
      <div class="create-account-button">
        <button
          class="create-account-button__button"
          type="submit"
          :disabled="loadingCreateAccount"
          @click="createAccount"
        >
          <p class="mb-0" v-if="!loadingCreateAccount">
            {{ $t("createAccount.button") }}
          </p>
          <b-spinner v-if="loadingCreateAccount" variant="primary" label="Spinning"></b-spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { generalMessage } from "../../utils/ErrorMessages";
import errorValidation from "../../Extend/ValidationCreateAccount";
import CreateAccountMagayaForm from "./components/CreateAccountMagayaInputs.vue";
import SetFavicon from "../../utils/SetFavicon";

export default {
  name: "CreateAccountStandard",
  components: {
    CreateAccountMagayaForm,
  },
  mixins: [errorValidation],
  data() {
    return {
      accountMagayaForm: {
        name: "Name",
        company_name: "",
        company_address: "",
        phone: "",
        first_name: "",
        lastname: "",
        email: "",
        country_code: "",
        zip_code: "",
        city: "",
        state_code: "",
        tax_id: "",
        accounting: "",
        website: "",
        magaya_company_password: "",
        confirm_password: "",
        magaya_company_username: "",
      },
      email_updates: false,
      checkedTerms: false,
      loadingCreateAccount: false,
    };
  },
  async beforeCreate() {
    const magayaId = this.$route.query.magaya_network_id;
    const response = await this.$store.dispatch("magaya/getMagayaCompanyExist", magayaId);
    this.existMagayaCompany = response.exist;
    SetFavicon(true);
  },
  methods: {
    async createAccount() {
      const isValidForm = await this.$refs["create-account-inputs-data"].validateMagayaForm();
      if (!isValidForm) {
        return;
      }
      this.loadingCreateAccount = true;
      const payload = { ...this.accountMagayaForm };
      payload.country_code = payload.country_code.name;
      payload.magaya_user_guid = this.$route.query.magaya_user_guid;
      payload.magaya_network_id = this.$route.query.magaya_network_id;
      payload.magaya_company_guid = this.$route.query.magaya_company_guid;
      const response = await this.$store.dispatch("user/createMagayaUser", payload);
      if (response) {
        generalMessage("successfully registered");
        await this.$router.push({ name: "LoginMagaya" });
      }
      this.loadingCreateAccount = false;
    },
    updateCheckedTerms(value) {
      this.checkedTerms = value;
    },
    updateEmailUpdates(value) {
      this.email_updates = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-account-standard {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/images/camion-autonomo-de-aurora.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  @media (max-width: 680px) {
    height: auto;
  }
  &-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column !important;
    width: 100%;
    max-width: 95vw;
    margin-top: 2%;
    padding: 2% 4%;
    background-color: $color-white;
    border-radius: 30px;
    z-index: 1;
    box-shadow: 2px 2px 4px #6b7489;
  }
}

.create-account-header {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  &__back-button {
    margin: 0 auto 0 0;
    @include go-back-button;
    &__icon {
      @include go-back-button-icon;
    }
  }
}

.create-account-button {
  font-family: $font-family-portal;
  width: 100%;
  &__button {
    @include primary-button(40px);
    margin-top: 2%;
    background: #383b48 !important;
    width: 25%;
    @include font-button-text;
    &:hover {
      background: #6b7489 !important;
    }
  }
  @media (max-width: 900px) {
    &__button {
      width: 40%;
    }
  }
  @media (max-width: 680px) {
    &__button {
      @include primary-button(48px);
      width: 60%;
      margin-top: 20px;
    }
  }
}

::v-deep .create-account-form__border:hover {
  box-shadow: 5px 4px 5px -2px #c4c4c4;
}

::v-deep .create-account-form__border:focus-within {
  box-shadow: 5px 4px 5px -2px #818181;
}

::v-deep .search-input:hover {
  box-shadow: 5px 4px 5px -2px #818181;
}

::v-deep .search-input:focus-within {
  box-shadow: 5px 4px 5px -2px #c4c4c4;
}
</style>
