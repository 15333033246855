function setFavicon(isMagaya) {
  /* eslint-disable global-require */
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  if (isMagaya) {
    link.href = require("../assets/images/MAGAYA-LOGO.png");
  } else {
    const logoUrl = require("../assets/images/GLT-LOGISTICS-(TRADEMARK-NEGATIVO).png");
    link.href = JSON.parse(localStorage.getItem("user-settings"))?.logo || logoUrl;
  }
}

export default setFavicon;
