export default {
  data() {
    return {
      error: {
        companyName: false,
        companyAddress: false,
        phoneNumber: false,
        taxID: false,
        firstName: false,
        lastName: false,
        accounting: false,
        email: false,
        password: false,
        confirmPassword: false,
        website: false,
        accounts: false,
        terms: false,
        countryCode: false,
        stateCode: false,
        city: false,
        zipCode: false,
        is_branded: false
      },
      inputLimits: {
        company_name: {
          min: 1,
          max: 150,
        },
        company_address: {
          min: 3,
          max: 150
        },
        phone: {
          min: 7,
          max: 30
        },
        zip_code: {
          min: 2,
          max: 20
        },
        city: {
          min: 2,
          max: 25
        },
        state_code: {
          min: 0,
          max: 50
        },
        password: {
          min: 8,
          max: 16
        }
      }
    };
  },
  methods: {
    validationsError() {
      // Validación de existencia y límites de caracteres
      this.error.companyName = !this.accountForm.company_name ||
        this.accountForm.company_name.length < this.inputLimits.company_name.min ||
        this.accountForm.company_name.length > this.inputLimits.company_name.max;

      this.error.companyAddress = !this.accountForm.company_address ||
        this.accountForm.company_address.length < this.inputLimits.company_address.min ||
        this.accountForm.company_address.length > this.inputLimits.company_address.max;

      this.error.phoneNumber = !this.accountForm.phone ||
        this.accountForm.phone.length < this.inputLimits.phone.min ||
        this.accountForm.phone.length > this.inputLimits.phone.max;

      this.error.firstName = !this.accountForm.first_name;

      this.error.lastName = !this.accountForm.lastname;

      this.error.email = !this.accountForm.email || !this.validateEmails();

      this.error.password = !this.accountForm.password ||
        this.accountForm.password.length < this.inputLimits.password.min ||
        this.accountForm.password.length > this.inputLimits.password.max;

      this.error.confirmPassword = !this.accountForm.confirm_password;

      this.error.countryCode = !this.accountForm.country_code;

      this.error.city = !this.accountForm.city ||
        this.accountForm.city.length < this.inputLimits.city.min ||
        this.accountForm.city.length > this.inputLimits.city.max;

      this.error.stateCode = !this.accountForm.state_code ||
        this.accountForm.state_code.length > this.inputLimits.state_code.max;

      this.error.zipCode = !this.accountForm.zip_code ||
        this.accountForm.zip_code.length < this.inputLimits.zip_code.min ||
        this.accountForm.zip_code.length > this.inputLimits.zip_code.max;

      this.error.accounts = !this.accountForm.accounts;
    },
    validateEmails() {
      const regexp = new RegExp(/^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z0-9-]+)*$/);
      return regexp.test(this.accountForm.email);
    }
  },
};
