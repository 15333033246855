<template>
  <div class="create-account-form">
    <div class="create-account-form__grid">
      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.companyName") }}
          <span
            class="required-field"
            v-show="error.company_name"
            v-b-popover.hover.top="$t('createAccount.error.companyName')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.company_name"
            tabindex="1"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.companyName')"
            ></i>
          </button>
        </div>
      </div>
      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.companyAddress") }}
          <span
            class="required-field"
            v-show="error.company_address"
            v-b-popover.hover.top="$t('createAccount.error.companyAddress')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.company_address"
            tabindex="2"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.companyAddress')"
            ></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.phoneNumber") }}
          <span
            class="required-field"
            v-show="error.phone"
            v-b-popover.hover.top="$t('createAccount.error.phoneNumber')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="number"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.phone"
            tabindex="3"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.firstName") }}
          <span
            class="required-field"
            v-show="error.first_name"
            v-b-popover.hover.top="$t('createAccount.error.firstName')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.first_name"
            tabindex="4"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.lastName") }}
          <span
            class="required-field"
            v-show="error.lastname"
            v-b-popover.hover.top="$t('createAccount.error.lastName')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.lastname"
            tabindex="5"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.email") }}
          <span
            class="required-field"
            v-show="error.email"
            v-b-popover.hover.top="$t('createAccount.error.email')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="email"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.email"
            tabindex="6"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.email')"
            ></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group">
        <label for="countryCode" class="create-account-form__label">
          {{ $t("createAccount.countryCode") }}
          <span
            class="required-field"
            v-show="error.country_code"
            v-b-popover.hover.top="$t('createAccount.error.countryCode')"
            >*</span
          >
        </label>
        <Dropdown
          class="create-account-form__border"
          v-model="accountMagayaForm.country_code"
          :options="countries"
          optionLabel="name"
          :filter="true"
          :showClear="true"
          tabindex="7"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.zipCode") }}
          <span
            class="required-field"
            v-show="error.zip_code"
            v-b-popover.hover.top="$t('createAccount.error.zipCode')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.zip_code"
            tabindex="8"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.city") }}
          <span
            class="required-field"
            v-show="error.city"
            v-b-popover.hover.top="$t('createAccount.error.city')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.city"
            tabindex="9"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.stateCode") }}
          <span
            class="required-field"
            v-show="error.state_code"
            v-b-popover.hover.top="$t('createAccount.error.stateCode')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.state_code"
            tabindex="10"
          />
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.taxID") }}
          <span
            class="required-field"
            v-show="error.tax_id"
            v-b-popover.hover.top="$t('createAccount.error.taxID')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.tax_id"
            tabindex="11"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.taxID')"
            ></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.accounting") }}
          <span
            class="required-field"
            v-show="error.accounting"
            v-b-popover.hover.top="$t('createAccount.error.accounting')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="email"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.accounting"
            tabindex="12"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.accounting')"
            ></i>
          </button>
        </div>
      </div>

      <div class="create-account-form__group">
        <label class="create-account-form__label">
          {{ $t("createAccount.website") }}
          <span
            class="required-field"
            v-show="error.website"
            v-b-popover.hover.top="$t('createAccount.error.website')"
            >*</span
          >
        </label>
        <div class="create-account-form__border">
          <input
            type="text"
            class="create-account-form__input"
            v-model.trim="accountMagayaForm.website"
            tabindex="13"
          />
          <button class="tooltip-icon" type="button">
            <i
              class="ion ion-information-circle-outline"
              v-b-popover.hover.top="$t('createAccount.message.website')"
            ></i>
          </button>
        </div>
      </div>
      <div class="create-account-form__group" v-if="this.$route.name == 'CreateNewAccount'">
        <label class="create-account-form__label">
          {{ $t("createAccount.accounts") }}
          <span
            class="required-field"
            v-show="error.accounts"
            v-b-popover.hover.top="$t('createAccount.error.accounts')"
            >*</span
          >
        </label>
        <AccountIdsSearcher
          v-model="accountMagayaForm.accounts"
          :remountSearcher="remountSearcher"
          :enableEditing="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { countries } from "countries-list";
import Dropdown from "primevue/dropdown";
import AcceptTerms from "@/Extend/AcceptTerms";
import errorValidation from "@/Extend/ValidationCreateAccount";
import changeTypePasswords from "../../../Extend/changeTypePasswords";
import AccountIdsSearcher from "../../../components/AccountIdsSearcher.vue";
import { UserMagayaSchema } from "../../../schemas/UserMagayaSchema";

export default {
  name: "CreateAccountInputs",
  components: {
    Dropdown,
    AccountIdsSearcher,
  },
  mixins: [changeTypePasswords, AcceptTerms, errorValidation],
  data() {
    return {
      remountSearcher: 0,
      accountMagayaForm: {
        name: "Name",
        company_name: "",
        company_address: "",
        phone: "",
        first_name: "",
        lastname: "",
        email: "",
        country_code: "",
        zip_code: "",
        city: "",
        state_code: "",
        tax_id: "",
        accounting: "",
        website: "",
        terms: true,
        isMagayaCompanyExist: null,
      },
      email_updates: true,
      checkedTerms: false,
      countries: null,
      error: {},
    };
  },
  watch: {
    accountMagayaForm: {
      deep: true,
      handler() {
        this.$emit("input", this.accountMagayaForm);
      },
    },
    "accountMagayaForm.terms": {
      handler() {
        this.$emit("updateCheckedTerms", this.accountMagayaForm.terms);
      },
    },
    email_updates() {
      this.$emit("updateEmailUpdates", this.email_updates);
    },
  },
  created() {
    let countriesList = Object.keys(countries).map((key) => countries[key]);
    const USAObject = countriesList.find((country) => country.name === "United States");
    countriesList = countriesList.filter((country) => country.name !== "United States");
    countriesList.unshift(USAObject);
    this.countries = countriesList;
  },
  methods: {
    async validateMagayaFormFields() {
      this.error = {};
      try {
        const isValid = await UserMagayaSchema.validate(this.accountMagayaForm, {
          abortEarly: false,
        });
        return isValid;
      } catch (error) {
        this.error = {};
        error.inner.forEach((element) => {
          this.error[element.path] = true;
        });
        return false;
      }
    },
    async validateMagayaForm() {
      const fieldsValidation = await this.validateMagayaFormFields();
      if (fieldsValidation) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Modal.scss";

.create-account-form {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
  }
  &__group {
    margin-right: 3%;
    margin-left: 3%;
    &--password {
      position: relative;
      @media (max-width: 680px) {
        margin: 10px 3%;
      }
    }
  }
  &__label {
    @include label-input-information;
  }
  &__border {
    @include input-information;
    justify-content: center;
    align-items: center;
  }
  &__input {
    @include input-password;
  }
  @media (max-width: 900px) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 680px) {
    width: 100%;
    &__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.create-account-checkboxes {
  @extend %flex-stretch;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: left;
    margin: 5px 0px 0px 5px;
    label {
      margin: 0px 0px 0px 10px;
      color: #00166b;
    }
  }
  &__accept-terms {
    margin: 0px 0px 0px 15px;
    &:hover {
      text-decoration: underline;
      color: rgb(0, 0, 168);
    }
  }
}
.tooltip-icon {
  @include icon-input;
  color: #00166b !important;
  padding: 0;
  margin: 0;
}
.required-field {
  color: $color-error;
}
.message-error {
  position: absolute;
  color: $color-error;
  margin-left: 3px;
  bottom: -12px;
  font-size: 0.8rem;
}

///****Modified primeVue styles-dropdown****\\\\
::v-deep .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  font-family: $font-family-portal;
  color: #00166b;
  margin-right: 0;
}
::v-deep .p-dropdown:not(.p-disabled):hover {
  border: 2px solid rgb(221, 221, 221);
}
::v-deep .p-dropdown:not(.p-disabled).p-focus {
  border: 2px solid rgb(221, 221, 221);
}
::v-deep .p-dropdown {
  align-items: center;
}

::v-deep .p-dropdown .p-dropdown-panel {
  max-width: 90%;
}

::v-deep .p-dropdown .p-dropdown-label {
  font-family: $font-family-portal;
  color: #00166b;
  text-align: left;
  font-size: 14px;
  padding-left: 20px;
}
::v-deep .p-dropdown-items {
  font-family: $font-family-portal;
  color: #00166b;
}
::v-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  color: #00166b;
}

///****Modified primeVue styles-Checkbox****\\\\
::v-deep .p-checkbox .p-checkbox-box {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #798499;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  background: #17d76e;
  background-color: #17d76e;
  border-color: #798499;
  &:hover {
    background: #17d76e;
    border-color: #798499;
  }
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #798499;
  box-shadow: none;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #0222aa;
}
::v-deep .search-input .search-input__main-content {
  flex-basis: 95%;
}
::v-deep .popover {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__icon {
    margin-right: 5px;
    font-size: 18px;
    &-false {
      color: $color-error;
    }
    &-true {
      color: #6b7489;
    }
  }
}
</style>
