import * as yup from "yup";

// eslint-disable-next-line import/prefer-default-export
export const UserMagayaSchema = yup.object().shape({
  name: yup.string().required(),
  company_name: yup.string().required(),
  company_address: yup.string().required(),
  phone: yup.number().required().positive().integer(),
  first_name: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().email().required(),
  country_code: yup.object().required(),
  zip_code: yup.string().required(),
  city: yup.string().required(),
  state_code: yup.string().required(),
  tax_id: yup.string(),
  accounting: yup.string(),
  website: yup.string(),
});
